import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button,Dialog,DialogActions,DialogContent,DialogTitle,TextField} from "@mui/material";
import './DialogLogin.scss'


function DialogLogin(props) {
    const {openDialogLogin, login, setOpenDialogLogin} = props
    const [textUserName, setTextUserName] = useState('')
    const [textPassword, setTextPassword] = useState('')
    const handleSetTextUserName = (e)=>{
        setTextUserName(e.target.value)
    }
    const handleSetTextPassword = (e)=>{
        setTextPassword(e.target.value)
    }
    return (  
    <Dialog open={openDialogLogin} className='dialog-login'>
        <DialogTitle className='login-header'>Master Data Management</DialogTitle>
        <DialogContent>
            <Box className='dialog-login-custom'>
                <TextField
                    label="Username"
                    // value={selectMachineType}
                    onChange={handleSetTextUserName}
                    variant="standard"
                >
                </TextField>
                <TextField
                    label="Password"
                    // value={selectMachineType}
                    onChange={handleSetTextPassword}
                    variant="standard"
                    type="password"
                >
                </TextField>
            </Box>
        </DialogContent>
        <DialogActions className='login-action-btn'>
          <Button variant="contained" className='btn-custom' onClick={()=>login(textUserName,textPassword)}>Login</Button>
          <Button variant="contained" className='btn-custom' onClick={()=>setOpenDialogLogin(false)}>Cancel</Button>
        </DialogActions>
    </Dialog>
    )
}

export default DialogLogin
