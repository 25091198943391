
const { faMap,
    faObjectsColumn, 
    faLineColumns,
    faDesktop, 
    faTablet, 
    faRankingStar, 
    faChartGantt, 
    faChartColumn, 
    faCalendarDay, 
    faCalendarWeek, 
    faCalendarDays, 
    faSquarePollVertical, 
    faFishBones, 
    faChartLineDown, 
    faFileContract, 
    faLightEmergencyOn, 
    faClockEight, 
    faMoon, 
    faFileCircleExclamation, 
    faFileBinary, 
    faFileCircleQuestion, 
    faGear,
    faRobot, 
    faUserRobot, 
    faCupTogo, 
    faCommentExclamation, 
    faUserHelmetSafety, 
    faCalendarClock, 
    faUmbrella, 
    faCalendarLinesPen, 
    faTimer, 
    faGaugeLow} = require("@fortawesome/pro-thin-svg-icons");
//sanko 
// const config = 'RGF0YSBTb3VyY2U9emVuLWEuY3Mxemc2NG5iMnVyLmFwLXNvdXRoZWFzdC0xLnJkcy5hbWF6b25hd3MuY29tO0luaXRpYWwgQ2F0YWxvZz1tc3NxbDIwMTkuc2Vlc2V0LnJkcy5zYW5rbztVc2VyIElEPXplbmFseXNlO1Bhc3N3b3JkPXZlcnlHb29kMjQqNztDb25uZWN0IFRpbWVvdXQ9MTIwO0VuY3J5cHQ9RmFsc2U7VHJ1c3RTZXJ2ZXJDZXJ0aWZpY2F0ZT1GYWxzZQ=='
//pistil
// const config = 'RGF0YSBTb3VyY2U9c2Vlc2V0ZGJjbG91ZC5jczF6ZzY0bmIydXIuYXAtc291dGhlYXN0LTEucmRzLmFtYXpvbmF3cy5jb207SW5pdGlhbCBDYXRhbG9nPW1zc3FsMjAxNC5zZWVzZXQucmRzLnBpc3RpbDtVc2VyIElEPXplbmFseXNlO1Bhc3N3b3JkPXplbmFseXNlNTU1OTQ7Q29ubmVjdCBUaW1lb3V0PTEyMDtFbmNyeXB0PUZhbHNlO1RydXN0U2VydmVyQ2VydGlmaWNhdGU9RmFsc2U='
// honda  Data Source=SEESET_SVR;Initial Catalog=mssql2019.seeset.local.honda;User ID=sa;Password=555;Connect Timeout=120;Encrypt=False;TrustServerCertificate=False
// honda const config = 'RGF0YSBTb3VyY2U9MTkyLjE2OC4xLjIwMDtJbml0aWFsIENhdGFsb2c9bXNzcWwyMDE5LnNlZXNldC5sb2NhbC5ob25kYTtVc2VyIElEPXNhO1Bhc3N3b3JkPTU1NTtDb25uZWN0IFRpbWVvdXQ9MTIwO0VuY3J5cHQ9RmFsc2U7VHJ1c3RTZXJ2ZXJDZXJ0aWZpY2F0ZT1GYWxzZQ=='
// const config = 'RGF0YSBTb3VyY2U9MTkyLjE2OC4wLjE7SW5pdGlhbCBDYXRhbG9nPW1zc3FsMjAxOS5zZWVzZXQubG9jYWwuaG9uZGE7VXNlciBJRD1zYTtQYXNzd29yZD01NTU7Q29ubmVjdCBUaW1lb3V0PTEyMDtFbmNyeXB0PUZhbHNlO1RydXN0U2VydmVyQ2VydGlmaWNhdGU9RmFsc2U='
// Pistil
const config = 'RGF0YSBTb3VyY2U9bW9rb2p1ZGIuY3Mxemc2NG5iMnVyLmFwLXNvdXRoZWFzdC0xLnJkcy5hbWF6b25hd3MuY29tO0luaXRpYWwgQ2F0YWxvZz1tc3NxbDIwMTYuc2Vlc2V0LnJkcy5waXN0aWw7VXNlciBJRD16ZW5hbHlzZTtQYXNzd29yZD0yMDIwMDMxNnplbmFseXNlO0Nvbm5lY3QgVGltZW91dD0xMjA7RW5jcnlwdD1GYWxzZTtUcnVzdFNlcnZlckNlcnRpZmljYXRlPUZhbHNl'
export const MENU = [
    {KEY: 0, LABEL: 'LAYOUT', ICON: faMap,PATH:'seeset/real-time/layout', IsSubMenu: false,},
    {KEY: 1, LABEL: 'CARD', ICON: faObjectsColumn,PATH:'seeset/real-time/card', IsSubMenu: false,},
    {
        // KEY: 2, LABEL: 'LIST STATUS', ICON: faLineColumns, PATH:'seeset/real-time/list-status', IsSubMenu: false,
        KEY: 2, LABEL: 'LIST', ICON: faLineColumns,  PATH:'seeset/real-time/list-status', IsSubMenu: true,
        Submenu: [
            {KEY: 2, LABEL: 'STATUS', ICON: faDesktop, PATH:'seeset/real-time/list-status', IsSubMenu: false,},
            {KEY: 4, LABEL: 'PROGRESS', ICON: faTablet, PATH:'seeset/real-time/list-progress', IsSubMenu: false,},
            {KEY: 5, LABEL: 'OEE', ICON: faTablet, PATH:'seeset/real-time/list-oee', IsSubMenu: false,}
        ]
    },
    {
        // KEY: 6, LABEL: 'SPEED BOARD', ICON: faRankingStar, PATH:'seeset/real-time/live-speed', IsSubMenu: false,
        // KEY: 7, LABEL: 'LIVE BOARD', ICON: faRankingStar, PATH:'seeset/real-time/time-board', IsSubMenu: false,
        KEY: 7, LABEL: 'LIVE BOARD', ICON: faRankingStar, PATH:'', IsSubMenu: true,
        Submenu: [
            {KEY: 7, LABEL: 'TIME', ICON: faTimer, PATH:'seeset/real-time/time-board', IsSubMenu: false,},
            {KEY: 8, LABEL: 'SPEED', ICON: faGaugeLow, PATH:'seeset/real-time/live-speed', IsSubMenu: false,}
        ]
    },
    {
        // KEY: 10, LABEL: 'TIME LINE', ICON: faChartGantt, PATH:'seeset/timeline', IsSubMenu: false,
        KEY: 9, LABEL: 'TIME LINE', ICON: faChartGantt, PATH:'', IsSubMenu: true,
        Submenu: [
            {KEY: 10, LABEL: 'DESKTOP', ICON: faDesktop, PATH:'seeset/timeline', IsSubMenu: false,},
            {KEY: 11, LABEL: 'TABLET', ICON: faTablet, PATH:'seeset/machine-timeline', IsSubMenu: false,}
        ]
    },
    {
        // KEY: 12, LABEL: 'QUANTITY',ICON: faChartColumn,PATH:'seeset/chart/quantity', IsSubMenu: false, 
        KEY: 12, LABEL: 'CHARTS',ICON: faChartColumn,PATH:'', IsSubMenu: true, 
        Submenu: [
            {KEY: 13, LABEL: 'DAILY', ICON: faCalendarDay, PATH:'seeset/chart/daily', IsSubMenu: false,},
            {KEY: 14, LABEL: 'WEEKLY', ICON: faCalendarWeek, PATH:'seeset/chart/weekly', IsSubMenu: false,},
            {KEY: 15, LABEL: 'MONTHLY', ICON: faCalendarDays, PATH:'seeset/chart/monthly', IsSubMenu: false,},
            {KEY: 16, LABEL: 'QUANTITY', ICON: faCalendarDays, PATH:'seeset/chart/quantity', IsSubMenu: false,},
            {KEY: 17, LABEL: 'UTILIZATION', ICON: faSquarePollVertical, PATH:'seeset/chart/utilization', IsSubMenu: false,},
            {KEY: 18, LABEL: 'ROOT CAUSE', ICON: faFishBones, PATH:'seeset/chart/root-cause', IsSubMenu: false,},
            {KEY: 19, LABEL: 'WASTE TIME', ICON: faChartLineDown, PATH:'seeset/chart/waste-time', IsSubMenu: false,}
        ]
    },
    {
        // KEY: 21, LABEL: 'PRODUCTION', ICON: faFileCircleExclamation, PATH:'seeset/report/production', IsSubMenu: false,
        KEY: 20, LABEL: 'REPORT', ICON: faFileContract, PATH:'', IsSubMenu: true, 
        Submenu: [
            {KEY: 21, LABEL: 'PRODUCTION', ICON: faFileCircleExclamation, PATH:'seeset/report/production', IsSubMenu: false,},
            {
                KEY: 22, LABEL: 'STATUS', ICON: faLightEmergencyOn, PATH:'', IsSubMenu: true,
                Submenu: [
                    {KEY: 23, LABEL: 'HOURLY', ICON: faClockEight, PATH:'seeset/report/hourly', IsSubMenu: false,},
                    {KEY: 24, LABEL: 'SHIFT', ICON: faMoon, PATH:'seeset/report/shift', IsSubMenu: false,},
                    {KEY: 25, LABEL: 'DAILY', ICON: faCalendarDay, PATH:'seeset/report/daily', IsSubMenu: false,},
                    {KEY: 26, LABEL: 'WEEKLY', ICON: faCalendarWeek, PATH:'seeset/report/weekly', IsSubMenu: false,},
                    {KEY: 27, LABEL: 'MONTHLY', ICON: faCalendarDays, PATH:'seeset/report/monthly', IsSubMenu: false,},
                ]
            },
            {KEY: 28, LABEL: 'LOSS TIME', ICON: faFileCircleExclamation, PATH:'seeset/report/loss-time/', IsSubMenu: false,},
            {KEY: 29, LABEL: 'LOSSTIME BY MACHINE', ICON: faFileBinary, PATH:'seeset/report/loss-time-by-machine/', IsSubMenu: false,},
            {KEY: 30, LABEL: 'LOSSTIME BY REASON', ICON: faFileCircleQuestion, PATH:'seeset/report/loss-time-by-reason/', IsSubMenu: false,}
        ]
    },
    {KEY: 31, LABEL: 'PLAN IMPORT', ICON: faObjectsColumn,PATH:'http://chifahservice.zenalyse.com:5831/?a='+config + '#en', IsSubMenu: false,},
    {
        KEY: 32, LABEL: 'CONFIG', ICON: faGear, PATH:'', IsSubMenu: true,
        Submenu: [
            {KEY: 33, LABEL: 'MACHINE', ICON: faRobot, PATH:'seeset/config/machine', IsSubMenu: false,},
            {KEY: 34, LABEL: 'MACHINE TYPE', ICON: faUserRobot, PATH:'seeset/config/machine-type', IsSubMenu: false,},
            {KEY: 35, LABEL: 'BREAK GROUP', ICON: faCupTogo, PATH:'seeset/config/break-group', IsSubMenu: false,},
            {KEY: 36, LABEL: 'LOSS CAUSE', ICON: faCommentExclamation, PATH:'seeset/config/loss-cause', IsSubMenu: false,},
            {KEY: 37, LABEL: 'NG', ICON: faCommentExclamation, PATH:'seeset/config/ng', IsSubMenu: false,},
            {KEY: 38, LABEL: 'ROOT CAUSE', ICON: faFishBones, PATH:'seeset/config/root-cause', IsSubMenu: false,},
            {KEY: 39, LABEL: 'PEOPLE', ICON: faUserHelmetSafety, PATH:'seeset/config/people', IsSubMenu: false,},
            {KEY: 40, LABEL: 'WORKDAY', ICON: faCalendarClock, PATH:'seeset/config/workday', IsSubMenu: false,},
            {KEY: 41, LABEL: 'HOLIDAY', ICON: faUmbrella, PATH:'seeset/config/holiday', IsSubMenu: false,},
            {KEY: 42, LABEL: 'SCHEDULE', ICON: faCalendarLinesPen, PATH:'seeset/config/schedule', IsSubMenu: false,},
        ]
    },
]
