import React, {useCallback, useEffect, useState} from 'react'
import BackgroundMenu from '../BackgroundMenu/BackgroundMenu'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PATH, PATH_LOGIN, API_URL} from '../../configs/variable'
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";
import {Box,IconButton, Grid } from "@mui/material"
import {MENU} from '../../configs/Menu_config'
import {find} from "lodash"
import DialogLogin from '../DialogLogin/DialogLogin';
import DialogError from '../DialogError/DialogError';
import axios from "axios"
import './MenuBox.scss'
import Cookies from 'js-cookie'
const image = require('../../asset/imgbackground/logo.png')

function MenuBox(props) {
    const [menuData, setMenuData] = useState([])
    const [showArrow, setShowArrow] = useState(false)
    const [openDialogLogin, setOpenDialogLogin] = useState(false)
    const [openDialogError, setOpenDialogError] = useState(false)
    const [subMenu, setSubMenu] = useState([])
    const [textHeader, setTextHeader] = useState('REAL-TIME MONITORING SYSTEM')

    const goToPage = (path) =>{
        window.location.href = PATH + path;
    }
    const clickMenu = useCallback((menu) => {
        if(menu.LABEL === 'CONFIG'){
            setSubMenu(menu.Submenu)
            setOpenDialogLogin(true)
            // renderMenu(menu.Submenu, true)
        }else if(menu.LABEL === 'PLAN IMPORT'){
            var importURL = menu.PATH;
            var win = window.open(importURL, '_blank');
            win.focus();
        } else if(menu.IsSubMenu === true){
            setTextHeader(menu.LABEL)
            renderMenu(menu.Submenu, true)
        }else {
            goToPage(menu.PATH)
        }
    },[setSubMenu,setOpenDialogLogin])
    const login = async (username,password)=>{
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/employee/login`,{
            "username": username,
            "password": password
        })
        const response = result.data.result
        if(response.status_login === true){
            setTextHeader('CONFIG')
            renderMenu(subMenu, true)
            setOpenDialogLogin(false)
        }else {
            setOpenDialogError(true)
        }
    }
    const renderMenu = (dataMenu, arrow) => {
        const boxMenu = <Box className='box-container'>
                {dataMenu.map((data)=>{
                    return (
                    <Box className='box-menu' onClick={() => clickMenu(data)}>
                        <Box className='menu-icon'>
                            <FontAwesomeIcon icon={data.ICON}></FontAwesomeIcon>
                        </Box>
                        <div className='text-label'>{data.LABEL}</div>
                    </Box>
                )
                })}
            </Box>
        arrow === true ? setShowArrow(true) : setShowArrow(false)
        setMenuData(boxMenu)
    }
    const checkAuth = useCallback(async ()=>{
        const jwt = Cookies.get('JWT')
        try{ 
            // const result = await axios.get(`${process.env.REACT_APP_API_URL}/login/auth`,
            // const result = await axios.get(`https://sanko-seeset.zenalyse.com/api/seeset/login/auth`,
            // {
            //     headers: {
            //         'Authorization': `Bearer ${jwt}`,
            //         'Content-Type': 'application/json'
            //     },
            // })
            const pathname = window.location.pathname
            if(pathname === '/seeset/menu/config')
            {
                // console.log(MENU)
                let menustate = find(MENU, menu => menu.LABEL === 'CONFIG')
                setTextHeader(menustate.LABEL)
                renderMenu(menustate.Submenu, true)
            }else if(pathname === '/seeset/menu/list'){
                let menustate = find(MENU, menu => menu.LABEL === 'LIST')
                setTextHeader(menustate.LABEL)
                renderMenu(menustate.Submenu, true)
            }else if(pathname === '/seeset/menu/live'){
                let menustate = find(MENU, menu => menu.LABEL === 'LIVE BOARD')
                setTextHeader(menustate.LABEL)
                renderMenu(menustate.Submenu, true)
            }else if(pathname === '/seeset/menu/chart'){
                
                let menustate = find(MENU, menu => menu.LABEL === 'CHARTS')
                setTextHeader(menustate.LABEL)
                renderMenu(menustate.Submenu, true)
            }else if(pathname === '/seeset/menu/report'){
                
                let menustate = find(MENU, menu => menu.LABEL === 'REPORT')
                setTextHeader(menustate.LABEL)
                renderMenu(menustate.Submenu, true)
            }else if(pathname === '/seeset/menu/report/status'){
                
                let menustate = find(MENU, menu => menu.LABEL === 'REPORT')
                let menuSubmenu = find(menustate.Submenu, menu => menu.LABEL === 'STATUS')
                setTextHeader(menuSubmenu.LABEL)
                // console.log(menustate)
                renderMenu(menuSubmenu.Submenu, true)
                // console.log(menustate)
            }else{
                renderMenu(MENU)
            }
        }
        catch(err){
            // const error = err.response
            // if(error.data.error.status === 401 || error.data.error.status === 403){
            //     window.location.href = PATH_LOGIN
            // }
        }
    },[MENU])
    const clickArrow = useCallback(() => {
        renderMenu(MENU, false)
        setTextHeader('REAL-TIME MONITORING SYSTEM')
    },[setTextHeader,renderMenu,MENU])

    useEffect(()=>{
        checkAuth()
    },[checkAuth])
    return (
        <Box>
            <Box className='box-header'>
                <Box>
                    <img className='img_logo' src={image} alt=''/>
                </Box>
                <Box className='header-text'>
                    {textHeader}
                </Box>
                <Box>
                    <IconButton className='btn-menu' style={{display: showArrow === true ? 'block' : 'none'}} size="small" onClick={()=>{clickArrow()}}>
                        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                    </IconButton>
                </Box>
            </Box>
            <BackgroundMenu />
            {menuData}
            <DialogLogin openDialogLogin={openDialogLogin} login={login} setOpenDialogLogin={setOpenDialogLogin}/>
            <DialogError openDialogError={openDialogError} setOpenDialogError={setOpenDialogError}/>
        </Box>
    )
}

export default MenuBox
