import React, {useCallback} from 'react'
import { Box } from "@mui/material"
import './BackgroundMenu.scss'
const image = require('../../asset/imgbackground/index_cover.jpg')

function BackgroundMenu(props) {

    return (
        <Box className='layout_background'>
            <img className='img_layout_background' src={image} alt=''/>
        </Box>
    )
}

export default BackgroundMenu;
