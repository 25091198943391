import {createSlice} from '@reduxjs/toolkit'

export const selectedMachine = createSlice({
    name: 'selectedMachine',
    initialState: '',
    reducers: {setSelectedMachine: (state, action) => state = action.payload}
})

export const {setSelectedMachine} = selectedMachine.actions
export default selectedMachine.reducer
