import React from 'react'
import MenuBox from "./components/MenuBox/MenuBox"
import {Helmet} from "react-helmet"
import './App.scss'
import {Box} from "@mui/material"
function App() {
    return (
        <>
            <Helmet>
                <title>SEESET</title>
            </Helmet>
            <Box className="app-container">
                <MenuBox/>
            </Box>
        </>
    )
}

export default App
